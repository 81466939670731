var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import WorkView from "~/components/collection-manage/all-case/work-view.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Layout, Dependencies } from "~/core/decorator";
import { HistoryCaseService } from "~/services/domain-service/history-case.service";
import { State } from "vuex-class";
var WithdrawalForm = /** @class */ (function (_super) {
    __extends(WithdrawalForm, _super);
    function WithdrawalForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: "",
            certificateNo: "",
            delegationDate: [],
        };
        _this.dataSet = [];
        _this.dialog = { view: false };
        _this.money = '';
        _this.rules = {
            certificateNo: [{ min: 6, message: '请输入至少6位的身份证号', trigger: 'blur' }]
        };
        _this.caseId = "";
        return _this;
    }
    WithdrawalForm.prototype.mounted = function () {
        this.refreshData();
    };
    WithdrawalForm.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.historyCaseService.getHisCaseForWorkOrder(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
        this.amount();
    };
    WithdrawalForm.prototype.amount = function () {
        var _this = this;
        this.historyCaseService.getHisCaseAmtForWorkOrder(this.searchModel)
            .subscribe(function (data) {
            _this.money = data;
        });
    };
    WithdrawalForm.prototype.appendWorkorder = function (scope) {
        var _this = this;
        this.loading.state = true;
        this.historyCaseService.changeWorkOrderType(scope.row.id, this.loading)
            .subscribe(function (data) {
            _this.$message.success("操作成功！");
            _this.refreshData();
        });
    };
    WithdrawalForm.prototype.workView = function (scope) {
        this.caseId = scope.row.id;
        this.dialog.view = true;
    };
    __decorate([
        Dependencies(PageService)
    ], WithdrawalForm.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], WithdrawalForm.prototype, "sortService", void 0);
    __decorate([
        Dependencies(HistoryCaseService)
    ], WithdrawalForm.prototype, "historyCaseService", void 0);
    __decorate([
        State
    ], WithdrawalForm.prototype, "principalList", void 0);
    WithdrawalForm = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                WorkView: WorkView
            }
        })
    ], WithdrawalForm);
    return WithdrawalForm;
}(Vue));
export default WithdrawalForm;
