var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { HistoryCaseService } from "~/services/domain-service/history-case.service";
var WorkView = /** @class */ (function (_super) {
    __extends(WorkView, _super);
    function WorkView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dataSet = [];
        _this.dialog = { repeat: false };
        _this.currentId = '';
        _this.approvalModel = {
            replayContent: ''
        };
        _this.approvalRules = {
            replayContent: [
                { required: true, message: "请输入回复结果", trigger: "blur" }
            ]
        };
        return _this;
    }
    WorkView.prototype.refreshData = function () {
        var _this = this;
        this.historyCaseService.getHistoryFollowRecord(this.caseId, this.pageService, this.sortService)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    WorkView.prototype.cancel = function () {
        this.dialog.repeat = false;
        var form = this.$refs['approval-form'];
        form.resetFields();
    };
    WorkView.prototype.repeatContent = function (row) {
        this.dialog.repeat = true;
        this.currentId = row.id;
    };
    WorkView.prototype.approvalCommit = function () {
        var _this = this;
        var form = this.$refs['approval-form'];
        form.validate(function (valid) {
            if (!valid)
                return;
            _this.historyCaseService.replayOtherFollowRecord(_this.approvalModel, _this.currentId, _this.currentId)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.cancel();
                _this.refreshData();
            }, function (err) { return _this.$message.error(err.msg); });
        });
    };
    __decorate([
        Dependencies(PageService)
    ], WorkView.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], WorkView.prototype, "sortService", void 0);
    __decorate([
        Dependencies(HistoryCaseService)
    ], WorkView.prototype, "historyCaseService", void 0);
    __decorate([
        Prop({ required: true, default: "" })
    ], WorkView.prototype, "caseId", void 0);
    WorkView = __decorate([
        Component({
            components: {
                DataBox: DataBox
            }
        })
    ], WorkView);
    return WorkView;
}(Vue));
export default WorkView;
