var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { domainService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
import { Service } from "~/core/service";
import { FilterService } from "~/utils/filter.service";
var HistoryCaseService = /** @class */ (function (_super) {
    __extends(HistoryCaseService, _super);
    function HistoryCaseService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * 历史案件查询（案件管理）
     */
    HistoryCaseService.prototype.getHisCaseForCaseManage = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.getHisCaseForCaseManage,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                handsNumber: data.handsNumber,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                city: data.city,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                deleteCaseDateStart: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").start,
                deleteCaseDateEnd: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").end,
                leaveFlag: data.leaveFlag,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 历史案件查询（调收管理）
     */
    HistoryCaseService.prototype.getHisCaseForCollManage = function (data, page, sort, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.historyCaseController.getHisCaseForCollManage,
            data: {
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                handsNumber: data.handsNumber,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                city: data.city,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                account: data.account,
                principalId: data.principalId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 查看欠款总额
     */
    HistoryCaseService.prototype.getHisCaseAmtForCollManage = function (data, loading) {
        var delegationDate = FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd");
        var endCaseDate = FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd");
        return this.netService.send({
            server: domainService.historyCaseController.getHisCaseAmtForCollManage,
            data: {
                personalName: data.personalName,
                certificateNo: data.certificateNo,
                handsNumber: data.handsNumber,
                city: data.city,
                phone: data.phone,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                delegationDateStart: delegationDate.start,
                delegationDateEnd: delegationDate.end,
                endCaseDateStart: endCaseDate.start,
                endCaseDateEnd: endCaseDate.end,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                principalId: data.principalId,
            },
            loading: loading,
        });
    };
    /**
     * 删除案件查询（案件管理）
     */
    HistoryCaseService.prototype.getDeleteCaseForCaseManage = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.getDeleteCaseForCaseManage,
            data: {
                principalId: data.principalId,
                personalName: data.personalName,
                caseDataStatus: data.caseDataStatus,
                certificateNo: data.certificateNo,
                batchNumber: data.batchNumber,
                leftAmtStart: data.leftAmt.min,
                leftAmtEnd: data.leftAmt.max,
                handsNumber: data.handsNumber,
                overduePeriodsStart: data.overduePeriods.min,
                overduePeriodsEnd: data.overduePeriods.max,
                overdueDaysStart: data.overdueDays.min,
                overdueDaysEnd: data.overdueDays.max,
                phone: data.phone,
                city: data.city,
                cardNo: data.cardNo,
                address: data.address,
                employerName: data.employerName,
                delegationMonth: data.delegationMonth,
                cardNoType: data.cardNoType,
                unhealthyFlag: data.unhealthyFlag,
                receiveName: data.receiveName,
                batchNo: data.batchNo,
                extensionInfo: data.extensionInfo,
                extensionAgent: data.extensionAgent,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
                endCaseDateStart: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").start,
                endCaseDateEnd: FilterService.dateRanageFormat(data.endCaseDate, "yyyy-MM-dd").end,
                deleteCaseDateStart: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").start,
                deleteCaseDateEnd: FilterService.dateRanageFormat(data.deleteCaseDate, "yyyy-MM-dd").end,
                leaveFlag: data.leaveFlag,
                account: data.account,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 历史案件查询（工单处理）
     */
    HistoryCaseService.prototype.getHisCaseForWorkOrder = function (data, page, sort, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.getHisCaseForWorkOrder,
            data: {
                principalId: data.principalId,
                certificateNo: data.certificateNo,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 更改工单处理状态
     */
    HistoryCaseService.prototype.changeWorkOrderType = function (id, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.changeWorkOrderType,
            data: {
                hisCaseId: id,
            },
            loading: loading,
        });
    };
    /**
     * 添加历史案件跟进记录
     */
    HistoryCaseService.prototype.saveHisFollowRecord = function (model, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.saveHisFollowRecord,
            data: {
                followTime: FilterService.dateTimeFormat(model.followTime),
                content: model.content,
                certificateNo: model.certificateNo,
                delegationDate: model.delegationDate,
                principalId: model.principalId,
                type: model.type,
            },
            loading: loading,
        });
    };
    /**
     * 查询历史案件工单跟进记录
     */
    HistoryCaseService.prototype.getHistoryFollowRecord = function (caseId, page, sort, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.getHistoryFollowRecord,
            data: {
                caseId: caseId,
            },
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 回复工单
     */
    HistoryCaseService.prototype.replayOtherFollowRecord = function (model, id, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.replayOtherFollowRecord,
            data: {
                replayContent: model.replayContent,
                id: id,
            },
            loading: loading,
        });
    };
    /**
     * 执行页案件查询
     * @param collectionId 案件Id
     * @param loading 加载等待
     */
    HistoryCaseService.prototype.caseCollectionSearch = function (collectionId, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.caseCollectionSearch,
            append: [collectionId],
            loading: loading,
        });
    };
    /**
     * 退案工单查询委案总额和欠款总额
     */
    HistoryCaseService.prototype.getHisCaseAmtForWorkOrder = function (data, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.getHisCaseAmtForWorkOrder,
            data: {
                principalId: data.principalId,
                certificateNo: data.certificateNo,
                delegationDateStart: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").start,
                delegationDateEnd: FilterService.dateRanageFormat(data.delegationDate, "yyyy-MM-dd").end,
            },
            loading: loading,
        });
    };
    /**
     * 恢复退案案件
     */
    HistoryCaseService.prototype.recoverPauseCase = function (caseIds, endDate, loading) {
        return this.netService.send({
            server: domainService.historyCaseController.recoverPauseCase,
            data: {
                caseIds: caseIds,
                endDate: endDate,
            },
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], HistoryCaseService.prototype, "netService", void 0);
    return HistoryCaseService;
}(Service));
export { HistoryCaseService };
